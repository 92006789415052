<script setup lang="ts">
import { ValidationObserver } from 'vee-validate'
import { onUnmounted } from 'vue'
import emitter from '/~/core/emitter'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseMetafield from '/~/components/base/metafield/base-metafield.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { StatementAccount } from '/~/composables/statements'
import { useEditAccount } from './edit-account'

const props = defineProps<{ account: StatementAccount }>()
const emit = defineEmits<{
  (event: 'save' | 'hide'): void
}>()

const {
  accountForm,
  isProcessing,
  accountNameRef,
  accountName,
  metafieldsSchema,
  backendErrors,
  form,
  onCancel,
  onSave,
  onClear,
} = useEditAccount(props, emit)

onUnmounted(() => {
  emitter.off('metafields:validate')
})
</script>
<template>
  <base-mdl width="sme" border-radius="xl" :padding="false">
    <template #header>
      <div class="relative text-center">
        <div
          class="mx-auto my-6 flex h-16 w-16 items-center justify-center rounded-lg bg-primary text-white"
        >
          <base-icon svg="heroicons/solid/pencil-square" :size="38" />
        </div>
        <span class="absolute right-0 top-0 mr-6 mt-6">
          <base-button
            icon="heroicons/outline/x-mark"
            :size="32"
            @click="onCancel()"
          />
        </span>
      </div>
    </template>

    <h2 class="text-center text-2xl text-eonx-neutral-800">Edit account</h2>

    <validation-observer
      ref="accountForm"
      v-slot="{ valid }"
      tag="div"
      class="px-8 pb-6"
    >
      <base-input
        ref="accountNameRef"
        v-model="accountName"
        :validation="{
          rules: 'required',
          name: 'Account name',
        }"
        label="Account name"
        required
        autofocus
        class="mt-12"
        entry-class="rounded !h-11 mt-1"
        label-class="!leading-normal !p-0"
      >
        <template slot="inner-filter">
          <div class="mx-3 px-4">
            <base-button size="sm" look="link" @click="onClear">
              <span class="text-sm">Clear</span>
            </base-button>
          </div>
        </template>
      </base-input>
      <base-metafield
        v-model="form"
        :schema="metafieldsSchema"
        :backend-errors="backendErrors"
        class="mt-6"
      />
      <div class="mt-12 grid grid-cols-2 gap-2">
        <base-button
          class="!h-12 flex-auto"
          look="outlined-color"
          size="lge"
          @click="onCancel"
        >
          Cancel
        </base-button>
        <base-button
          class="!h-12 flex-auto"
          size="lge"
          :disabled="!valid || isProcessing"
          @click="onSave"
        >
          <span v-if="isProcessing">Processing...</span>
          <span v-else>Save</span>
        </base-button>
      </div>
    </validation-observer>
  </base-mdl>
</template>
